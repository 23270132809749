
















































































































import { Component, Vue } from 'vue-property-decorator';
import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';
import RetirementCardViewModel
  from '@/vue-app/view-models/components/goals-dashboard/retirement-card-view-model';

@Component({
  name: 'RetirementFundCard',
  components: {
    LoadingDataCard,
    FlagshipLinkGoalsWizardWealth: () => import('@/vue-app/components/flagship/flagship-link-goals/FlagshipLinkGoalsWizardWealth.vue'),
    ModalPrepareDocumentsWealth: () => import('@/vue-app/components/flagship/flagship-link-goals/steps-wealth/ModalPrepareDocumentsWealth.vue'),
  },
})
export default class RetirementFundCard extends Vue {
  retirement_fund_card_view_model = Vue.observable(new RetirementCardViewModel());

  async created() {
    await this.retirement_fund_card_view_model.initialize();
  }
}
